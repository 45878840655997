import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { UserState } from "../models/user";
import { fetchUser } from "./ActionCreator";

const initialState: UserState = {
	token: "",
	refreshToken: "",
	expiration: "",
	error: "",
	isLoader: false,
	role: "",
	fullName: "",
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		userLogout(state) {
			state.token = "";
			state.refreshToken = "";
			state.expiration = "";
			state.role = "";
			state.fullName = "";
			Cookies.remove("token");
			Cookies.remove("refreshToken");
			Cookies.remove("expiration");
			Cookies.remove("role");
			Cookies.remove("fullName");
		},
		userAutoLogin(state) {
			const token = Cookies.get("token");
			const refreshToken = Cookies.get("refreshToken");
			const expiration = Cookies.get("expiration");
			const role = Cookies.get("role");
			const fullName = Cookies.get("fullName");
			if (
				token !== undefined &&
				refreshToken !== undefined &&
				expiration !== undefined &&
				role !== undefined &&
				fullName !== undefined
			) {
				state.token = token;
				state.refreshToken = refreshToken;
				state.expiration = expiration;
				state.role = role;
				state.fullName = fullName;
			} else {
				state.token = "";
				state.refreshToken = "";
				state.expiration = "";
				state.role = "";
				state.fullName = "";
				Cookies.remove("token");
				Cookies.remove("refreshToken");
				Cookies.remove("expiration");
				Cookies.remove("role");
				Cookies.remove("fullName");
			}
		},
	},
	extraReducers: {
		[fetchUser.pending.type]: (state) => {
			state.isLoader = true;
		},
		[fetchUser.fulfilled.type]: (state, action: PayloadAction<UserState>) => {
			state.isLoader = false;
			state.token = action.payload.token;
			state.refreshToken = action.payload.refreshToken;
			state.expiration = action.payload.expiration;
			state.fullName = action.payload.fullName;
			state.role = action.payload.role;
		},
	},
});

export default userSlice.reducer;
