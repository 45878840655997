/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import styles from "../styles/About.module.css";
export const About = (): JSX.Element => {
	return (
		<div>
			<section className={styles.topSection}>
				<div className={styles.container}>
					<div className={styles.containerTop}>
						<h1>О нас</h1>
						<p>
							Компания "Лада Деталь 56" - фирма по продажам авто компонентов для отечественных автомобилей в
							Оренбургской области.
						</p>
						<p>
							Миссия компании — ежедневно заботиться об исправности автомобилей, постоянно повышая уровень безопасности
							всех участников дорожного движения, комфорта наших сотрудников и защищенности наших партнеров по бизнесу.
						</p>
					</div>
				</div>
			</section>
			<section>
				<div className={styles.container}>
					<div className={styles.descHead}>
						<h2>Факты о компании</h2>
						<p>
							Мы имеем более чем 20-летний опыт работы на рынке авто запчастей и гарантируем качество поставляемых вам
							товаров.
						</p>
					</div>
					<div className={styles.facts}>
						<div className={styles.factsItems}>
							<img src="../img/thumb.svg" alt="thumb" width={80} height={80}></img>
							<p>День рождения 1 апреля 2004г.</p>
						</div>
						<div className={styles.factsItems}>
							<img src="../img/thumb.svg" alt="thumb" width={80} height={80}></img>
							<p>Более 80 брендов</p>
						</div>
						<div className={styles.factsItems}>
							<img src="../img/thumb.svg" alt="thumb" width={80} height={80}></img>
							<p>70 прямых контактов с заводами-изготовителями </p>
						</div>
						<div className={styles.factsItems}>
							<img src="../img/thumb.svg" alt="thumb" width={80} height={80}></img>
							<p>Склад 1500м²</p>
						</div>
						<div className={styles.factsItems}>
							<img src="../img/thumb.svg" alt="thumb" width={80} height={80}></img>
							<p>Собственный автопарк грузовых автомобилей</p>
						</div>
					</div>
				</div>
			</section>
			<section className={styles.map}>
				<h2>Наш адрес</h2>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2085.4817847128247!2d55.1036925945349!3d51.724080706863255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x417bfa8f747a4e99%3A0x548357367b73ceda!2z0JHQtdC70Y_QtdCy0YHQutCw0Y8g0YPQuy4sIDYg0LrQvtGA0L_Rg9GBIDIsINCe0YDQtdC90LHRg9GA0LMsINCe0YDQtdC90LHRg9GA0LPRgdC60LDRjyDQvtCx0LsuLCA0NjAwMzQ!5e0!3m2!1sru!2sru!4v1669155577438!5m2!1sru!2sru"
					width="100%"
					height="650"
				></iframe>
			</section>
		</div>
	);
};
