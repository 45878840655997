import { useContext } from "react";
import { ModalContext } from "../../../context/Modal.context";
import styles from "./ModalSpareProduct.module.css";
import cn from "classnames";

export const ModalSpareProduct = (): JSX.Element => {
	const { propsModal } = useContext(ModalContext);

	return (
		<div className={styles.info}>
			<img
				src={propsModal.image}
				alt={propsModal.name}
				width="200px"
				height="200px"
				className={cn(styles.img, {
					[styles.noImg]: propsModal.image === "/img/rectangle.png",
				})}
			/>
			<div className={styles.text}>
				<h1>Название:</h1>
				<p>{propsModal.name}</p>
				<h1>Артикул:</h1>
				<p>{propsModal.vendorCode}</p>
				<h1>Производитель:</h1>
				<p>{propsModal.manufacturer.name}</p>
				<h1>Цена:</h1>
				<span>{propsModal.price} рублей</span>
				{propsModal.description && (
					<div className={styles.desc}>
						<h1>Описание:</h1>
						<p>{propsModal.description}</p>
					</div>
				)}
			</div>
		</div>
	);
};
