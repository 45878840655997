import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Redux from "react-redux";
import { setupStore } from "./store/store";
import React from "react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const store = setupStore();

root.render(
	// <React.StrictMode>
	<Redux.Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Redux.Provider>
	// </React.StrictMode>
);
