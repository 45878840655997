import { useContext, useState } from "react";
import { Button, Loader } from "../components";
import styles from "../styles/Basket.module.css";
import cn from "classnames";
import { FaRegTrashAlt } from "react-icons/fa";
import { AppContext } from "../context/App.context";
import { nanoid } from "nanoid";
import { axiosPrivate } from "../settings/axios";
import { toast } from "react-toastify";

export const Basket = (): JSX.Element => {
	const { basket, setIsLoaderBasket } = useContext(AppContext);
	const [load, setLoad] = useState<boolean>(false);

	const changeAmount = async (id: string, amount: number) => {
		try {
			const { status } = await axiosPrivate.get(`/basket/${basket?.basketId}/changeamount/${id}?newAmount=${amount}`);
			if (status === 200) {
				setIsLoaderBasket && setIsLoaderBasket(true);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const deleteItem = async (id: string) => {
		try {
			const { status } = await axiosPrivate.get(`/basket/${basket?.basketId}/deletepart/${id}`);
			if (status === 200) {
				setIsLoaderBasket && setIsLoaderBasket(true);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const create = async () => {
		setLoad(true);
		try {
			const { status } = await axiosPrivate.get("/orders/create");
			if (status === 200) {
				setLoad(false);
				toast.success("Успешно");
				setIsLoaderBasket && setIsLoaderBasket(true);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	return basket?.manufacturersAutoParts && basket!.manufacturersAutoParts.length > 0 ? (
		<div className={styles.basketCardItems}>
			{load && <Loader />}
			{basket?.manufacturersAutoParts.map((d) => (
				<div key={nanoid()} className={styles.manufacturer}>
					<p className={styles.manufacturerName}>{d.manufacturer.name}</p>
					{d.orderParts.map((i) => (
						<div key={nanoid()} className={styles.item}>
							<img
								src={i.autoPart.image !== null ? i.autoPart.image : "/img/rectangle.png"}
								alt="engine"
								width={150}
								height={150}
								className={styles.img}
							/>
							<div className={styles.parts}>
								<div className={styles.info}>
									<p className={styles.title}>
										Название: <span>{i.autoPart.name}</span>
									</p>
									<p className={styles.vendorCode}>
										Артикул: <span>{i.autoPart.vendorCode}</span>
									</p>
									<p className={styles.price}>
										Цена: <span>{i.autoPart.price} руб</span>
									</p>
								</div>
								<div className={styles.buttonBlock}>
									<FaRegTrashAlt size={20} className={styles.trash} onClick={() => deleteItem(i.id)} />
									<div className={styles.buttonPick}>
										<Button
											appearance="red"
											className={cn({
												[styles.transparent]: i.amount === 1,
											})}
											onClick={() => changeAmount(i.id, i.amount - 1)}
										>
											-
										</Button>
										<input className={styles.input} defaultValue={i.amount} />
										<Button appearance="green" onClick={() => changeAmount(i.id, i.amount + 1)}>
											+
										</Button>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			))}
			<div className={styles.total}>
				<p className={styles.allPrice}>
					Итого: <span>{basket.totalPrice} руб</span>
				</p>
				<Button appearance="green" onClick={create}>
					Заказать
				</Button>
			</div>
		</div>
	) : (
		<div className={styles.notItem}>Корзина пустая</div>
	);
};
