/* eslint-disable no-restricted-globals */
import { useForm } from "react-hook-form";
import { Button, Input } from "../components";
import styles from "../styles/ChangePassword.module.css";
import cn from "classnames";
import { axiosPrivate } from "../settings/axios";
import { decodeToken } from "react-jwt";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ChangePassword = (): JSX.Element => {
	const navigate = useNavigate();

	const authFun = async (formData: any) => {
		try {
			const token: any = Cookies.get("token");

			const decodToken: any = decodeToken(token);
			const { status } = await axiosPrivate.post("/users/setpassword", {
				userId: decodToken.UserId,
				oldPassword: formData.oldPassword,
				newPassword: formData.newPassword,
			});
			if (status === 200) {
				toast.success("Пароль успешно изменен");
				reset();
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const {
		register,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm({
		mode: "all",
	});
	return (
		<div className={styles.content}>
			<form onSubmit={handleSubmit(authFun)} className={styles.changePassword}>
				<h1>Изменение пароля</h1>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.oldPassword,
					})}
				>
					<label className={cn("required")} htmlFor="oldPassword">
						Старый пароль
					</label>
					<Input
						type="password"
						id="oldPassword"
						{...register("oldPassword", {
							required: true,
						})}
						placeholder="Старый пароль"
					/>
					{errors?.oldPassword && <p>{errors.oldPassword.message?.toString()}</p>}
					{errors?.oldPassword && errors.oldPassword.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.newPassword,
					})}
				>
					<label className={cn("required")} htmlFor="newPassword">
						Новый пароль
					</label>
					<Input
						type="password"
						id="newPassword"
						{...register("newPassword", {
							required: true,
						})}
						placeholder="Новый пароль"
					/>
					{errors?.newPassword && <p>{errors.newPassword.message?.toString()}</p>}
					{errors?.newPassword && errors.newPassword.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.newPaswordTwo,
					})}
				>
					<label className={cn("required")} htmlFor="newPaswordTwo">
						Повторите пароль
					</label>
					<Input
						type="password"
						id="newPaswordTwo"
						{...register("newPaswordTwo", {
							required: true,
						})}
						placeholder="Повторите пароль"
					/>
					{errors?.newPaswordTwo && <p>{errors.newPaswordTwo.message?.toString()}</p>}
					{errors?.newPaswordTwo && errors.newPaswordTwo.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div className={styles.btnBlock}>
					<Button appearance="red" type="button" onClick={() => navigate(-1)}>
						Отменить
					</Button>
					<Button appearance="green" type="submit">
						Сменить пароль
					</Button>
				</div>
			</form>
		</div>
	);
};
