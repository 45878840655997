import { createContext, PropsWithChildren, useState } from "react";
import { Modal } from "../components";

export interface ModalContextInterface {
	openModal: boolean;
	typeModal: "editProduct" | "spareProduct" | "";
	propsModal?: any;
	setOpenModal?: (newOpenModal: boolean) => void;
	setTypeModal?: (newTypeModal: "editProduct" | "spareProduct" | "") => void;
	setPropsModal?: (newPropsModal: any) => void;
}

export const ModalContext = createContext<ModalContextInterface>({
	openModal: false,
	typeModal: "",
});

export const ModalContextProvider = ({ children }: PropsWithChildren<ModalContextInterface>): JSX.Element => {
	const [openModalState, setOpenModalState] = useState<boolean>(false);
	const [typeModalState, setTypeModalState] = useState<"editProduct" | "spareProduct" | "">("");
	const [propsState, setPropsModalState] = useState<any>();

	const setOpenModal = (newOpenModal: boolean) => {
		setOpenModalState(newOpenModal);
	};
	const setTypeModal = (newTypeModal: "editProduct" | "spareProduct" | "") => {
		setTypeModalState(newTypeModal);
	};
	const setPropsModal = (newPropsModal: any) => {
		setPropsModalState(newPropsModal);
	};

	return (
		<ModalContext.Provider
			value={{
				openModal: openModalState,
				typeModal: typeModalState,
				propsModal: propsState,
				setOpenModal,
				setTypeModal,
				setPropsModal,
			}}
		>
			<Modal />
			{children}
		</ModalContext.Provider>
	);
};
