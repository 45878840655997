/* eslint-disable array-callback-return */
import { createContext, PropsWithChildren, useState } from "react";
import { BasketInterface } from "../interface/Basket.interface";

export interface AppContextInterface {
	basket: BasketInterface | null;
	basketLength: number;
	isLoaderBasket: boolean;
	autoPartAmountChangedId: string | null;
	setBasket?: (newBaskeet: BasketInterface) => void;
	setIsLoaderBasket?: (newIsLoader: boolean) => void;
	setAutoPartAmountChangedId?: (newId: string | null) => void;
}

export const AppContext = createContext<AppContextInterface>({
	basket: null,
	basketLength: 0,
	isLoaderBasket: true,
	autoPartAmountChangedId: null,
});

export const AppContextProvider = ({ children }: PropsWithChildren<AppContextInterface>): JSX.Element => {
	const [basketState, setBasketState] = useState<BasketInterface | null>(null);
	const [basketLengthState, setBasketLengthState] = useState<number>(0);
	const [isLoaderBasketState, setIsLoaderBasketState] = useState<boolean>(true);
	const [autoPartAmountChangedIdState, setAutoPartAmountChangedIdState] = useState<string | null>(null);

	const setBasket = (newBaskeet: BasketInterface) => {
		let lengthBasket = 0;
		(() => {
			newBaskeet?.manufacturersAutoParts.map((i) => {
				lengthBasket = lengthBasket + i.orderParts.length;
			});
		})();
		setBasketLengthState(lengthBasket);
		setBasketState(newBaskeet);
		setIsLoaderBasketState(false);
	};

	const setIsLoaderBasket = (newIsLoader: boolean) => {
		setIsLoaderBasketState(newIsLoader);
	};
	const setAutoPartAmountChangedId = (newId: string | null) => {
		setAutoPartAmountChangedIdState(newId);
	};

	return (
		<AppContext.Provider
			value={{
				basket: basketState,
				basketLength: basketLengthState,
				isLoaderBasket: isLoaderBasketState,
				autoPartAmountChangedId: autoPartAmountChangedIdState,
				setBasket,
				setIsLoaderBasket,
				setAutoPartAmountChangedId,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
