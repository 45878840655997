import { useNavigate } from "react-router-dom";
import { Button } from "../components";
import styles from "../styles/Admin.module.css";

export const Admin = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<div className={styles.admin}>
			<Button appearance="orange" onClick={() => navigate("/admin/add-user")}>
				Добавить пользователя
			</Button>
			<Button appearance="orange" onClick={() => navigate("/admin/users")}>
				Список пользователей
			</Button>
		</div>
	);
};
