/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { Outlet, Route, Routes } from "react-router-dom";
import { withLayout } from "./Layout/Layout";
import { AddUser } from "./page/AddUser";
import { ChangePassword } from "./page/ChangePassword";
import { Basket } from "./page/Basket";
import { Home } from "./page/Home";
import { NotPage } from "./page/NotPage";
import { Orders } from "./page/Orders";
import { About } from "./page/About";
import { useDispatch } from "react-redux";
import { userSlice } from "./store/reducers/UserSlice";
import { RequireAuth } from "./settings/RoutePrivate.Auth";
import { Admin } from "./page/Admin";
import { Users } from "./page/Users";
import React, { useContext, useEffect } from "react";
import { axiosPrivate } from "./settings/axios";
import { AppContext } from "./context/App.context";
import { useAppSelector } from "./store/hooks/redux";

function App() {
	const dispatch = useDispatch();
	const { token, role } = useAppSelector((state) => state.user);
	const { setBasket, isLoaderBasket } = useContext(AppContext);

	setInterval(() => {
		dispatch(userSlice.actions.userAutoLogin());
	}, 1);

	useEffect(() => {
		token &&
			(async () => {
				{
					const { status, data } = await axiosPrivate.get(`/basket/list`);
					if (status === 200) {
						setBasket && setBasket(data);
					}
				}
			})();
	}, [isLoaderBasket, token]);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<RequireAuth>
						<Home />
					</RequireAuth>
				}
			/>
			<Route
				path="basket"
				element={
					<RequireAuth>
						<Basket />
					</RequireAuth>
				}
			/>
			<Route
				path="about"
				element={
					<RequireAuth>
						<About />
					</RequireAuth>
				}
			/>
			<Route
				path="change-password"
				element={
					<RequireAuth>
						<ChangePassword />
					</RequireAuth>
				}
			/>
			<Route
				path="orders"
				element={
					<RequireAuth>
						<Orders />
					</RequireAuth>
				}
			/>
			{role === "Admin" ? (
				<Route path="Admin" element={<Outlet />}>
					<Route
						path=""
						element={
							<RequireAuth>
								<Admin />
							</RequireAuth>
						}
					/>
					<Route
						path="add-user"
						element={
							<RequireAuth>
								<AddUser />
							</RequireAuth>
						}
					/>
					<Route
						path="users"
						element={
							<RequireAuth>
								<Users />
							</RequireAuth>
						}
					/>
				</Route>
			) : (
				<Route path="*" element={<NotPage />} />
			)}
			<Route path="*" element={<NotPage />} />
		</Routes>
	);
}

export default withLayout(App);
