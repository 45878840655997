import { useForm } from "react-hook-form";
import { Button, Input } from "../components";
import styles from "../styles/AddUser.module.css";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../settings/axios";
import { toast } from "react-toastify";

export const AddUser = (): JSX.Element => {
	const navigate = useNavigate();

	const authFun = async (formData: any) => {
		try {
			const { status } = await axiosPrivate.post("/register", formData, {
				headers: {
					Accept: "application/json-patch+json",
				},
			});
			if (status === 200) {
				toast.success("Пользователь добавлен, ему на почту придет пароль");
				reset();
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const {
		register,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm({
		mode: "all",
	});

	return (
		<div className={styles.content}>
			<form onSubmit={handleSubmit(authFun)} className={styles.addUser}>
				<h2>Добавление нового пользователя</h2>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.lastName,
					})}
				>
					<label className={cn("required")} htmlFor="lastName">
						Фамилия
					</label>
					<Input
						type="text"
						id="lastName"
						{...register("lastName", {
							required: true,
						})}
						placeholder="Фамилия"
					/>
					{errors?.lastName && <p>{errors.lastName.message?.toString()}</p>}
					{errors?.lastName && errors.lastName.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.firstName,
					})}
				>
					<label className={cn("required")} htmlFor="firstName">
						Имя
					</label>
					<Input
						type="text"
						id="firstName"
						{...register("firstName", {
							required: true,
						})}
						placeholder="Имя"
					/>
					{errors?.firstName && <p>{errors.firstName.message?.toString()}</p>}
					{errors?.firstName && errors.firstName.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.middleName,
					})}
				>
					<label className={cn("required")} htmlFor="middleName">
						Отчество
					</label>
					<Input
						type="text"
						id="middleName"
						{...register("middleName", {
							required: true,
						})}
						placeholder="Отчество"
					/>
					{errors?.middleName && <p>{errors.middleName.message?.toString()}</p>}
					{errors?.middleName && errors.middleName.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.email,
					})}
				>
					<label className={cn("required")} htmlFor="email">
						Почта
					</label>
					<Input
						type="text"
						id="email"
						{...register("email", {
							required: true,
						})}
						placeholder="Почта"
					/>
					{errors?.email && <p>{errors.email.message?.toString()}</p>}
					{errors?.email && errors.email.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.phone,
					})}
				>
					<label className={cn("required")} htmlFor="phone">
						Номер телефона
					</label>
					<Input
						type="text"
						id="phone"
						{...register("phone", {
							required: true,
						})}
						placeholder="Номер телефона"
					/>
					{errors?.phone && <p>{errors.phone.message?.toString()}</p>}
					{errors?.phone && errors.phone.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.company,
					})}
				>
					<label className={cn("required")} htmlFor="company">
						Компания
					</label>
					<Input
						type="text"
						id="company"
						{...register("company", {
							required: true,
						})}
						placeholder="Компания"
					/>
					{errors?.company && <p>{errors.company.message?.toString()}</p>}
					{errors?.company && errors.company.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div
					className={cn(styles.inputBlock, {
						[styles.errorInput]: errors.login,
					})}
				>
					<label className={cn("required")} htmlFor="login">
						Логин
					</label>
					<Input
						type="text"
						id="login"
						{...register("login", {
							required: true,
						})}
						placeholder="Логин"
					/>
					{errors?.login && <p>{errors.login.message?.toString()}</p>}
					{errors?.login && errors.login.message === "" && <p>Необходимо заполнить поле</p>}
				</div>
				<div className={styles.btnBlock}>
					<Button appearance="red" type="button" onClick={() => navigate(-1)}>
						Отмена
					</Button>
					<Button appearance="green" type="submit">
						Создать
					</Button>
				</div>
			</form>
		</div>
	);
};
