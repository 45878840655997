/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react";
import { ModalContext } from "../../context/Modal.context";
import { GrClose } from "react-icons/gr";
import { AutoPartBody } from "./AutoPartBody/AutoPartBody";
import { ModalSpareProduct } from "./ModalSpareProduct/ModalSpareProduct";

export const Modal = (): JSX.Element => {
	const modalRef = useRef<HTMLDialogElement>(null);
	const { openModal, typeModal, propsModal, setTypeModal, setPropsModal, setOpenModal } = useContext(ModalContext);
	const dialogNode = modalRef.current;

	useEffect(() => {
		if (dialogNode) {
			if (openModal) {
				dialogNode.showModal();
				document.getElementsByTagName("body")[0].style.overflow = "hidden";
			} else {
				document.getElementsByTagName("body")[0].style.overflow = "";
				setPropsModal && setPropsModal({});
				dialogNode.close();
			}
		}
	}, [openModal]);

	useEffect(() => {
		const modal = document.querySelector(".modal");
		modal?.addEventListener("click", (e) => {
			if (e.target === modal) {
				setPropsModal && setPropsModal({});
				closeModal();
			}
		});
	}, []);

	useEffect(() => {
		document.addEventListener("keyup", (e) => {
			if (e.key === "Escape") {
				closeModal();
			}
		});
	}, []);

	const closeModal = () => {
		setOpenModal && setOpenModal(false);
		setPropsModal && setPropsModal([]);
		setTypeModal && setTypeModal("");
	};

	const headerModal = () => {
		switch (typeModal) {
			case "spareProduct": {
				return (
					<>
						<p>{propsModal.name}</p>
						<GrClose className="closeModal" size={20} onClick={closeModal} />
					</>
				);
			}
			case "editProduct": {
				return (
					<>
						<p>Редактирование продукта</p>
						<GrClose className="closeModal" size={20} onClick={closeModal} />
					</>
				);
			}
			default:
				return (
					<>
						<p>header</p>
						<GrClose className="closeModal" size={20} onClick={closeModal} />
					</>
				);
		}
	};

	const bodyModal = () => {
		switch (typeModal) {
			case "spareProduct": {
				return <ModalSpareProduct />;
			}
			case "editProduct": {
				return <AutoPartBody />;
			}
			default:
				return <p>body modal</p>;
		}
	};

	return (
		<dialog ref={modalRef} className="modal">
			<div className="modal__inner">
				<div className="headerModal">{headerModal()}</div>
				<div className="bodyModal">{bodyModal()}</div>
			</div>
		</dialog>
	);
};
