import { ProductDivProps } from "./ProductDiv.props";
import styles from "./ProductDiv.module.css";
import { Button } from "../Button/Button";
import { useContext, useState } from "react";
import { ModalContext } from "../../context/Modal.context";
import { axiosPrivate } from "../../settings/axios";
import { AppContext } from "../../context/App.context";
import { ProductsInterface } from "../../interface/Products.interface";
import { toast } from "react-toastify";
import cn from "classnames";
import { useAppSelector } from "../../store/hooks/redux";
import { BsFillBasket3Fill, BsInfoCircle } from "react-icons/bs";

export const ProductDiv = ({ isLine, propsBlock, ...props }: ProductDivProps) => {
	const { role } = useAppSelector((state) => state.user);
	const { setOpenModal, setPropsModal, setTypeModal } = useContext(ModalContext);
	const { setIsLoaderBasket, setAutoPartAmountChangedId } = useContext(AppContext);
	const [amount, setAmount] = useState(1);

	const changeAmount = (amount: number) => {
		if (amount <= 0) setAmount(1);
		else if (amount >= propsBlock.amount) setAmount(propsBlock.amount);
		else if (amount > 0 && amount <= propsBlock.amount) {
			setAmount(amount);
		} else {
			toast.error("Ошибка");
		}
	};

	const addAutopart = async () => {
		try {
			const formData = new FormData();
			formData.append("AutoPartId", propsBlock.id);
			formData.append("Amount", amount.toString());
			const { status } = await axiosPrivate.post("/basket/addautopart", formData);
			if (status === 200) {
				propsBlock.amount -= amount;
				propsBlock.amountInBasket += amount;
				setAutoPartAmountChangedId && setAutoPartAmountChangedId(null);
				toast.success("Добавлено");
				setIsLoaderBasket && setIsLoaderBasket(true);
				setAutoPartAmountChangedId && setAutoPartAmountChangedId(propsBlock.id);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const openModal = (nameModal: "editProduct" | "spareProduct", props: ProductsInterface) => {
		setTypeModal && setTypeModal(nameModal);
		setOpenModal && setOpenModal(true);
		setPropsModal && setPropsModal(props);
	};

	return (
		<>
			{isLine ? (
				<div className={styles.gridBlock} {...props}>
					<img src={propsBlock.image} alt={propsBlock.name} height={200} width={200} className={styles.imgLine} />
					<div className={styles.info}>
						<div className={styles.topDataLine}>
							<b className={styles.textOverflow}>{propsBlock.name}</b>
						</div>
						<table className={styles.table}>
							<thead className={styles.thead}>
								<tr className={styles.tr}>
									<td className={styles.td}>Артикул</td>
									<td className={styles.td}>Поставщик</td>
									<td className={styles.td}>Количество на складе</td>
									<td className={styles.td}>Цена</td>
								</tr>
							</thead>
							<tbody>
								<tr className={styles.tr}>
									<td className={styles.td}>{propsBlock.vendorCode}</td>
									<td className={styles.td}>{propsBlock.manufacturer.name}</td>
									<td className={styles.td}>{propsBlock.amount}</td>
									<td className={styles.td}>{propsBlock.price} руб</td>
								</tr>
							</tbody>
						</table>
						<div className={styles.buttonGrid}>
							<Button
								appearance="orange"
								className={styles.buttonLine}
								onClick={() => openModal("spareProduct", propsBlock)}
							>
								<BsInfoCircle width={25} color={"white"} /> <span className={styles.textInButton}>Подробнее</span>
							</Button>
							{propsBlock.amount !== 0 ? (
								<>
									<div className={styles.buttonPickLine}>
										<Button
											appearance="red"
											className={cn(styles.smallButton, {
												[styles.transparent]: amount <= 1,
											})}
											disabled={amount <= 1 && true}
											onClick={() => changeAmount(amount - 1)}
										>
											-
										</Button>
										<input
											type={"number"}
											className={styles.input}
											onChange={(e) => changeAmount(e.target.value as unknown as number)}
											value={amount}
										/>
										<Button
											appearance="green"
											className={cn(styles.smallButton, {
												[styles.transparent]: amount >= propsBlock.amount,
											})}
											disabled={amount >= propsBlock.amount && true}
											onClick={() => changeAmount(amount + 1)}
										>
											+
										</Button>
										<Button appearance="orange" className={styles.buttonLine} onClick={() => addAutopart()}>
											<BsFillBasket3Fill width={25} color={"white"} />{" "}
											<span className={styles.textInButton}>Добавить в корзину</span>
										</Button>
									</div>
								</>
							) : (
								<></>
							)}
							{role === "Admin" && (
								<Button
									appearance="orange"
									className={(styles.buttonLine, styles.admin)}
									onClick={() => openModal("editProduct", propsBlock)}
								>
									Редактировать
								</Button>
							)}
						</div>
					</div>
					<div className={styles.square}>{propsBlock.amountInBasket}</div>
				</div>
			) : (
				<div className={styles.blockMainDiv} {...props}>
					<div className={styles.square}>{propsBlock.amountInBasket}</div>
					<div className={styles.imgBlock}>
						<span className={styles.scale}>
							<img
								src={propsBlock.image}
								alt={propsBlock.name}
								height={100}
								width={100}
								style={{ marginBottom: "5px", marginTop: "5px" }}
								className={styles.img}
							/>
							<span className={styles.name}>{propsBlock.name}</span>
						</span>
					</div>
					<b style={{ fontSize: "medium" }} className={styles.textOverflow}>
						{propsBlock.name}
					</b>
					<div style={{ maxWidth: "190px" }}>
						<p className={styles.medium}>
							<b className={styles.medium}>Артикул:</b> {propsBlock.vendorCode}
						</p>
						<p className={styles.medium}>
							<b className={styles.medium}>Поставщик:</b> {propsBlock.manufacturer.name}
						</p>
						<p className={styles.medium}>
							<b className={styles.medium}>На складе:</b> {propsBlock.amount}
						</p>
						<p className={styles.medium}>
							<b className={styles.medium}>Цена:</b> {propsBlock.price} руб
						</p>
					</div>
					<span className={styles.buttonDiv}>
						{role === "Admin" && (
							<Button
								appearance="orange"
								className={styles.button}
								onClick={() => openModal("editProduct", propsBlock)}
							>
								Редактировать
							</Button>
						)}
						{propsBlock.amount !== 0 ? (
							<>
								<div className={styles.buttonPick}>
									<Button
										appearance="red"
										className={cn({
											[styles.transparent]: amount <= 1,
										})}
										disabled={amount <= 1 && true}
										onClick={() => changeAmount(amount - 1)}
									>
										-
									</Button>
									<input
										type={"number"}
										className={styles.input}
										onChange={(e) => changeAmount(e.target.value as unknown as number)}
										value={amount}
									/>
									<Button
										appearance="green"
										className={cn({
											[styles.transparent]: amount >= propsBlock.amount,
										})}
										disabled={amount >= propsBlock.amount && true}
										onClick={() => changeAmount(amount + 1)}
									>
										+
									</Button>
								</div>
								<Button appearance="orange" className={styles.button} onClick={() => addAutopart()}>
									В корзину
								</Button>
							</>
						) : (
							<></>
						)}
						<Button appearance="orange" className={styles.button} onClick={() => openModal("spareProduct", propsBlock)}>
							Подробнее
						</Button>
					</span>
				</div>
			)}
		</>
	);
};
