import { Input } from "../../Input/Input";
import cn from "classnames";
import { createRef, ForwardedRef, useContext, useEffect, useState } from "react";
import { axiosPrivate } from "../../../settings/axios";
import styles from "./AutoPartBody.module.css";
import { Button } from "../../Button/Button";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import { ModalContext } from "../../../context/Modal.context";
import { Select } from "../../Select/Select";
import { toast } from "react-toastify";
import { HomeContext } from "../../../context/Home.context";
import { EditProductInterface } from "../../../interface/Products.interface";

export const AutoPartBody = (): JSX.Element => {
	const { propsModal, setOpenModal, setTypeModal } = useContext(ModalContext);
	const { setAutoPart } = useContext(HomeContext);
	const [image, setImage] = useState(propsModal.image);
	const [allManufacturers, setAllManufactories] = useState<{ id: string; name: string; selected: boolean }[]>([]);
	const [imagePreview, setImagePreview] = useState(<></>);
	const fileInput: ForwardedRef<HTMLInputElement> = createRef();
	console.log(propsModal);

	const { register, handleSubmit } = useForm({
		defaultValues: {
			_id: propsModal?._id,
			partImage: propsModal?.image,
			partName: propsModal?.name,
			partManufacturers: propsModal?.manufacturer?.id,
			partAmount: propsModal?.amount,
			partVendorCode: propsModal?.vendorCode,
			description: propsModal?.description,
		},
		mode: "all",
	});

	const _handleImageChange = (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setImage(typeof reader.result == "string" ? reader.result : "");
		};
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		(async () => {
			const { status, data } = await axiosPrivate.get("/manufacturer/list");
			if (status === 200) {
				setAllManufactories(
					data.map((x: any) => ({
						...x,
						selected: x.id === data.manufacturer ? true : false,
					}))
				);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (image) {
			setImagePreview(<img alt="" src={image} />);
		} else {
			setImagePreview(<div className={styles.previewText}>Изображение отсутствует</div>);
		}
	}, [image]);

	const onSubmit = async (data) => {
		try {
			const formData = new FormData();
			formData.append("Name", data.partName);
			formData.append("VendorCode", data.partVendorCode);
			formData.append("ManufacturerId", data.partManufacturers);
			formData.append("Amount", data.partAmount);
			formData.append("Image", image !== "" ? image : "");
			formData.append("Description", data.description !== null ? data.description : "");
			const { status } = await axiosPrivate.post(`/autopart/edit/${propsModal._id}`, formData);
			if (status === 200) {
				setAutoPart &&
					setAutoPart({
						_id: propsModal._id,
						image: image !== "" ? image : "",
						name: data.partName,
						vendorCode: data.partVendorCode,
						manufacturer: allManufacturers.find((x) => x.id === data.partManufacturers),
						amount: data.partAmount,
						description: data.description !== null ? data.description : "",
					} as EditProductInterface);
				setTypeModal && setTypeModal("");
				setOpenModal && setOpenModal(false);
				toast.success("Успешно");
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="partImage">
					<p>Изображение:</p>
				</label>
				<div className={styles.grid}>
					<Input
						id="partImage"
						{...register("partImage", {
							required: false,
						})}
						className={styles.fileInput}
						type="file"
						onChange={(e) => _handleImageChange(e)}
						ref={fileInput}
					/>
					<div
						className={styles.submitButton}
						onClick={() => {
							setImage("");
							fileInput.current!.value = "";
						}}
					>
						Очистить
					</div>
				</div>
				<div className={styles.imgPreview}>{imagePreview}</div>
			</div>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="partName">
					<p>Наименование:</p>
				</label>
				<Input
					{...register("partName", {
						required: false,
					})}
					type="text"
					id="partName"
				/>
			</div>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="partVendorCode">
					<p>Артикул:</p>
				</label>
				<Input
					placeholder={"Артикул"}
					{...register("partVendorCode", {
						required: false,
					})}
					type="text"
					id="partVendorCode"
				/>
			</div>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="partManufacturers">
					<p>Производитель:</p>
					<Select
						id="partManufacturers"
						{...register("partManufacturers", {
							required: false,
						})}
					>
						<option defaultValue={propsModal.manufacturer}>{propsModal.manufacturer?.name}</option>
						{allManufacturers
							.filter((item) => item.id !== propsModal.manufacturer?.id)
							.map((item) => (
								<option key={nanoid()} value={item.id}>
									{item.name}
								</option>
							))}
					</Select>
				</label>
			</div>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="partAmount">
					<p>Количество на складе:</p>
				</label>
				<Input
					{...register("partAmount", {
						required: false,
					})}
					type="number"
					id="partAmount"
				/>
			</div>
			<div className={styles.field}>
				<label className={cn("required")} htmlFor="description">
					<p>Описание:</p>
				</label>
				<Input
					{...register("description", {
						required: false,
					})}
					id="description"
				/>
			</div>
			<div className={styles.centerWrapper}>
				<Button appearance="green" type="submit" style={{ width: "200px" }}>
					Сохранить
				</Button>
			</div>
		</form>
	);
};
