import cn from "classnames";
import { Button, Input } from "../components";
import { useAppDispatch } from "../store/hooks/redux";
import { fetchUser } from "../store/reducers/ActionCreator";
import { useForm } from "react-hook-form";
import styles from "../styles/Login.module.css";
import { useContext } from "react";
import { AppContext } from "../context/App.context";

export interface DataLogin {
  userName: string;
  password: string;
}

export const Login = () => {
  const dispatch = useAppDispatch();
  const { setIsLoaderBasket } = useContext(AppContext);

  const authFun = async (formData: any) => {
    // await dispatch(fetchUser(formData));
    if ((await dispatch(fetchUser(formData))).type === "user/fetchLogin/rejected") {
      setError("userName", { message: "Ошибка, проверте введенные данные", type: "onChange" });
      setError("password", { message: "Ошибка, проверте введенные данные", type: "onChange" });
    }

    setIsLoaderBasket && setIsLoaderBasket(true);
  };

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  return (
    <form onSubmit={handleSubmit(authFun)} className={styles.login}>
      <h1>Вход</h1>
      <div
        className={cn(styles.inputBlock, {
          [styles.errorInput]: errors.userName,
        })}
      >
        <label className={cn("required")} htmlFor="userName">
          Логин
        </label>
        <Input
          type="text"
          id="userName"
          {...register("userName", {
            required: true,
          })}
          placeholder="Логин"
        />
        {errors?.userName && <p>{errors.userName.message?.toString()}</p>}
        {errors?.userName && errors.userName.message === "" && <p>Необходимо заполнить поле</p>}
      </div>
      <div
        className={cn(styles.inputBlock, {
          [styles.errorInput]: errors.password,
        })}
      >
        <label htmlFor="password" className={cn("required")}>
          Пароль
        </label>
        <Input
          type="password"
          id="password"
          {...register("password", {
            required: true,
          })}
          placeholder="Пароль"
        />
        {errors?.password && <p>{errors.password.message?.toString()}</p>}
        {errors?.password && errors.password.message === "" && <p>Необходимо заполнить поле</p>}
      </div>
      <Button appearance="green" type="submit">
        Войти
      </Button>
    </form>
  );
};
