/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { Button } from "../components";
import styles from "../styles/Orders.module.css";
import { nanoid } from "nanoid";
import { axiosPrivate } from "../settings/axios";
import { OrdersInterface } from "../interface/Orders.interface";
import { toast } from "react-toastify";

export const Orders = (): JSX.Element => {
	const [orders, setOrders] = useState<OrdersInterface[]>([]);

	useEffect(() => {
		(async () => {
			try {
				const { status, data } = await axiosPrivate.get<OrdersInterface[]>("/orders");
				if (status === 200) {
					setOrders(data.reverse());
				}
			} catch (e) {
				toast.error("Ошибка, попробуйте позже");
			}
		})();
	}, []);

	return orders.length > 0 ? (
		<div className={styles.main}>
			{orders.map((d) => (
				<div className={styles.orderList} key={d.id}>
					<div className={styles.order}>
						<div className={styles.title}>
							<p>
								Номер заказа: <span>{d.orderNumber}</span>
							</p>
							<p>
								Количество: <span>{d.orderParts.length}</span>
							</p>
							<p>
								Сумма: <span>{d.totalPrice} руб</span>
							</p>
							<p>
								Статус: <span>{d.orderStatus !== null ? d.orderStatus.name : "-"}</span>
							</p>
						</div>
						<Button
							id={d.id + "_btn"}
							appearance="orange"
							onClick={() => {
								const item = document.getElementById(d.id + "_test");
								if (item?.style.display === "none") {
									document.getElementById(d.id + "_btn")!.textContent = "Закрыть";
									item.style.display = "block";
								} else {
									document.getElementById(d.id + "_btn")!.textContent = "Раскрыть";
									item && (item.style.display = "none");
								}
							}}
						>
							Раскрыть
						</Button>
					</div>
					<div id={d.id + "_test"} style={{ display: "none", margin: 10 }}>
						{d.orderParts.map((i) => (
							<div className={styles.items} key={nanoid()}>
								<img
									src={i.autoPart.image !== null ? i.autoPart.image : "/img/rectangle.png"}
									alt="item"
									width={150}
									height={150}
								/>
								<div className={styles.desc}>
									<p>
										Название: <span>{i.autoPart.name}</span>
									</p>
									<p>
										Артикул: <span>{i.autoPart.vendorCode}</span>
									</p>
									<p>
										Количество: <span>{i.amount}</span>
									</p>
									<p>
										Цена: <span>{i.autoPart.price} руб</span>
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	) : (
		<div className={styles.notOrders}>Вы ничего не заказывали</div>
	);
};
