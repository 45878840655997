import { ButtonProps } from "./Button.props";
import cn from "classnames";
import styles from "./Button.module.css";

export const Button = ({ appearance = "orange", className, children, ...props }: ButtonProps): JSX.Element => {
	return (
		<button
			className={cn(styles.button, className, {
				[styles.orange]: appearance === "orange",
				[styles.red]: appearance === "red",
				[styles.green]: appearance === "green",
			})}
			{...props}
		>
			{children}
		</button>
	);
};
