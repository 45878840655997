import styles from "./Loader.module.css";
import { LoaderProps } from "./Loader.props";
import cn from "classnames";

export const Loader = ({ appearance = "all" }: LoaderProps): JSX.Element => {
	return (
		<div
			className={cn(styles.loader, {
				[styles.login]: appearance === "login",
				[styles.manufacturer]: appearance === "manufacturer",
			})}
		>
			<div className={styles.ldsRing}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
