/* eslint-disable jsx-a11y/anchor-is-valid */
import { SidebarProps } from "./Sidebar.props";
import styles from "./Sidebar.module.css";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/App.context";
import { Button } from "../../components";
import { useDispatch } from "react-redux";
import { userSlice } from "../../store/reducers/UserSlice";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseLine } from "react-icons/ri";

export const Sidebar = ({ role, fullName, className, ...props }: SidebarProps): JSX.Element => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { basketLength } = useContext(AppContext);

	function openNav() {
		document.getElementById("sidebar")!.style.width = "250px";
	}

	function closeSidebar() {
		document.getElementById("sidebar")!.style.width = "0";
	}

	return (
		<div className={cn(className, styles.sidebar)} {...props}>
			<GiHamburgerMenu size={30} className={styles.openbtn} onClick={openNav} />
			<img src="/img/logo.png" alt="" width={200} height={20} className={styles.logoBurger} />
			<div id="sidebar" className={styles.sidebarBlock}>
				<RiCloseLine className={styles.closeBtn} onClick={closeSidebar} color="var(--white)" />
				<nav className={styles.nav}>
					<Link to="/" onClick={closeSidebar}>
						Главная
					</Link>
					<Link to="/about" onClick={closeSidebar}>
						О нас
					</Link>
					<Link
						to="/basket"
						style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
						onClick={closeSidebar}
					>
						Корзина {basketLength > 0 ? <span>{basketLength}</span> : <></>}
					</Link>
					<p>{fullName}</p>
					<Link to="/change-password" onClick={closeSidebar}>
						Изменить пароль
					</Link>
					<Link to="/orders" onClick={closeSidebar}>
						История заказов
					</Link>
					{role === "Admin" ? (
						<Link to="/admin" onClick={closeSidebar}>
							Панель администратора
						</Link>
					) : (
						<></>
					)}
					<Button
						appearance="red"
						onClick={() => {
							dispatch(userSlice.actions.userLogout());
							closeSidebar();
						}}
					>
						Выход
					</Button>
				</nav>
			</div>
			<div className={styles.content}>
				<Link to="/">
					<img src="/img/logo.png" alt="" width={50} height={20} className={styles.logo} />
				</Link>
				<nav className={styles.nav}>
					<Link to="/about">О нас</Link>
					<Link to="/basket" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						Корзина {basketLength > 0 ? <span>{basketLength}</span> : <></>}
					</Link>
					<div className={styles.dropdown}>
						<p className={styles.profile}>Профиль</p>
						<div className={styles.dropdownContent}>
							<p>{fullName}</p>
							<Button appearance="orange" onClick={() => navigate("/change-password")}>
								Изменить пароль
							</Button>
							<Button appearance="orange" onClick={() => navigate("/orders")}>
								История заказов
							</Button>
							{role === "Admin" ? (
								<Button appearance="orange" onClick={() => navigate("/admin")}>
									Панель администратора
								</Button>
							) : (
								<></>
							)}
							<Button appearance="red" onClick={() => dispatch(userSlice.actions.userLogout())}>
								Выход
							</Button>
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
};
