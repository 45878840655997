import { LayoutProps } from "./Layout.props";
import styles from "./Layout.module.css";
import { FunctionComponent } from "react";
import { Sidebar } from "./Sidebar/Sidebar";
import { useAppSelector } from "../store/hooks/redux";
import { ModalContextProvider } from "../context/Modal.context";
import { AppContextProvider } from "../context/App.context";
import { HomeFilterProvider } from "../context/Home.context";
import { Footer } from "./Footer/Footer";
import { ToastContainer } from "react-toastify";

const Layout = ({ children }: LayoutProps): JSX.Element => {
	const { token, role, fullName } = useAppSelector((state) => state.user);
	return (
		<div className={styles.wrapper}>
			{token && <Sidebar className={styles.sidebar} role={role} fullName={fullName} />}
			<main className={styles.body}>{children}</main>
			{token && <Footer className={styles.footer} />}
		</div>
	);
};

export const withLayout = <T extends Record<string, unknown>>(Component: FunctionComponent<T>) => {
	return function withLayoutComponent(props: T): JSX.Element {
		return (
			<AppContextProvider basket={null} basketLength={0} isLoaderBasket autoPartAmountChangedId={null}>
				<HomeFilterProvider manufacturersFilter={null} autoPart={undefined}>
					<ModalContextProvider openModal={false} typeModal="">
						<Layout>
							<ToastContainer
								position="bottom-right"
								autoClose={5000}
								hideProgressBar
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss={false}
								draggable
								pauseOnHover
								theme="dark"
							/>
							<Component {...props} />
						</Layout>
					</ModalContextProvider>
				</HomeFilterProvider>
			</AppContextProvider>
		);
	};
};
