/* eslint-disable react-hooks/exhaustive-deps */
import { nanoid } from "nanoid";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Loader, ProductDiv } from "../components";
import { HomeContext } from "../context/Home.context";
import { Manufacturer, ProductsInterface } from "../interface/Products.interface";
import { axiosPrivate } from "../settings/axios";
import styles from "../styles/Home.module.css";
import { ImSearch } from "react-icons/im";
import { IoGridOutline, IoList, IoCloseSharp } from "react-icons/io5";
import cn from "classnames";

interface LeftPanelChild {
	id: string;
	name: string;
}

export const Home = (): JSX.Element => {
	const [pageSize, setPageSize] = useState(18);
	const [pageNumber, setPageNumber] = useState(0);
	const [pagesCount, setPagesCount] = useState(1);
	const [fetch, setFetch] = useState(true);

	const [tableData, setData] = useState<ProductsInterface[]>([]);
	const [leftPanelChildren, setLeftPanelChildren] = useState<LeftPanelChild[]>([]);
	const [leftPanelFilter, setLeftPanelFilter] = useState<string | undefined>("");
	const [localSearchString, setLocalSearchString] = useState<string | undefined>("");
	const [mainSearchString, setMainSearchString] = useState<string | undefined>("");

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isMob, setIsMob] = useState<boolean>(false);
	const [isLoadMan, setLoadMan] = useState<boolean>(false);
	const [isLoad, setIsLoad] = useState<boolean>(true);
	const [isLoadData, setIsLoadDate] = useState<boolean>(false);
	const [isListView, setIsListView] = useState<boolean>(localStorage.getItem("viewType") === "list" ? true : false);

	const { autoPart, manufacturersFilter, setManufacturersFilter } = useContext(HomeContext);

	useEffect(() => {
		getCount(manufacturersFilter, localSearchString);
	}, [manufacturersFilter]);

	useEffect(() => {
		let part = tableData.find((x) => x.id === autoPart?._id);
		if (part !== undefined) {
			part.amount = autoPart?.amount ?? 0;
			part.description = autoPart?.description ?? "";
			part.image = autoPart?.image ?? "";
			part.manufacturer = autoPart?.manufacturer ?? ({ id: "", name: "" } as Manufacturer);
			part.name = autoPart?.name ?? "";
			part.vendorCode = autoPart?.vendorCode ?? "";
			setData(tableData.map((x) => x));
		}
	}, [autoPart]);

	useEffect(() => {
		(async () => {
			console.log("fetch", fetch);
			console.log("page", pagesCount, pageSize, tableData.length, pagesCount / pageSize > tableData.length);
			if (fetch && tableData.length < pagesCount) {
				getAutoParts(manufacturersFilter, mainSearchString, tableData, pageNumber);
			}
		})();
	}, [fetch]);

	useEffect(() => {
		async function scroll() {
			const { status, data } = await axiosPrivate.get("/manufacturer/list");
			if (status) {
				setLeftPanelChildren(data);
			}
			document.addEventListener("scroll", scrollHandler);
			setPageSize(Math.floor(window.innerWidth / 270) * 4);
			if (window.innerWidth >= 700) {
				setIsMob(true);
				setIsListView(false);
			} else {
				setIsListView(true);
			}
			window.onresize = function () {
				if (window.innerWidth <= 700) {
					setIsListView(true);
					setIsMob(false);
				}
			};
		}
		scroll();
		return () => {
			window.removeEventListener("scroll", scrollHandler);
		};
	}, []);

	const getCount = async (manufacturersFilter: string[] | null, search: string | undefined) => {
		setMainSearchString(search);
		setData([]);
		const formData = new FormData();
		formData.append("SearchString", search ? search : "");
		manufacturersFilter?.forEach((x) => formData.append("SelectedManufactories", x));
		const { status, data } = await axiosPrivate.post(`/autopart/count`, formData);
		if (status) {
			getAutoParts(manufacturersFilter, search, [], 0);
			setPagesCount(data);
		}
	};

	const getAutoParts = async (
		manufacturerFilter: string[] | null,
		search: string | undefined,
		tableData: ProductsInterface[],
		pageNumber: number
	) => {
		isLoadMan !== true && setIsLoadDate(true);
		const formData = new FormData();
		formData.append("SearchString", search ? search : "");
		manufacturerFilter?.forEach((x) => formData.append("SelectedManufactories", x));
		const { status, data } = await axiosPrivate.post(
			`/autopart/list?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			formData
		);
		if (status === 200) {
			setData([
				...tableData,
				...data.map(
					(x: any) =>
						(x = {
							...x,
							_id: x.id,
							manufacturer: x.manufacturer === "" ? "-" : x.manufacturer,
							vendorCode: x.vendorCode === "" ? "-" : x.vendorCode,
							catalogCode: x.catalogCode === "" ? "-" : x.catalogCode,
							name: x.name === "" ? "-" : x.name,
							price: x.price === "" ? "-" : x.price,
							image: x.image !== null ? x.image : "/img/rectangle.png",
						})
				),
			]);
			setIsLoad(false);
			setPageNumber(pageNumber + 1);
			setFetch(false);
			setIsLoadDate(false);
			setLoadMan(false);
		}
	};

	const scrollHandler = (x: any) => {
		const a = window.innerHeight;
		const b = x.target.documentElement.scrollHeight;
		const c = x.target.documentElement.scrollTop;
		if (b - (a + c) < 500) {
			setFetch(true);
		}
	};

	const onClearFilter = () => {
		setLocalSearchString("");
		setMainSearchString("");
		setManufacturersFilter && setManufacturersFilter([]);
		getAutoParts(null, undefined, [], 0);
		setData([]);
		setPageNumber(0);
		const manufacturerFilterArr = document.getElementById("manufacturerFilter")?.children ?? [];
		for (let index = 0; index < manufacturerFilterArr.length; index++) {
			const childrenDiv = manufacturerFilterArr[index];
			(childrenDiv.children[1] as HTMLInputElement).checked = false;
		}
	};

	const setViewType = (isList: boolean) => {
		setIsListView(isList);
		localStorage.setItem("viewType", isList ? "list" : "notList");
	};

	const onChange = () => {
		var selectedCheckBoxes = document.querySelectorAll("input.manufacturerInput:checked");
		var checkedValues = Array.from(selectedCheckBoxes).map((cb: any) => cb.id);
		setManufacturersFilter && setManufacturersFilter(checkedValues);
		setLoadMan(true);
		setIsOpen(!isOpen);
	};
	const onLeftPanelFilterChange = async (value: string) => {
		setLeftPanelFilter(value);
		console.log(value);
		const { status, data } = await axiosPrivate.get(`/manufacturer/list?filter=${value}`);
		if (status) {
			setLeftPanelChildren(data);
		}
	};
	return !isLoad ? (
		<div className={styles.mainDiv}>
			<div className={styles.searchDivGrid}>
				<div className={cn(styles.search, styles.centerWrapper)}>
					<Input
						className={cn({ [styles.disabled]: isLoadData })}
						id={"searchInput"}
						style={{ minWidth: "40%" }}
						placeholder="Поиск"
						type={"text"}
						onKeyDown={(e) => {
							if (e.code === "Enter") getCount(manufacturersFilter, localSearchString);
						}}
						onChange={(e) => setLocalSearchString(e.target.value)}
						value={localSearchString}
					/>
					<ImSearch
						className={cn({ [styles.disabledWithoutBackground]: isLoadData })}
						style={{ marginLeft: "-35px" }}
						size={25}
						onClick={() => getCount(manufacturersFilter, localSearchString)}
					/>
				</div>
				<div className={styles.viewType}>
					<p>Вид отображения</p>
					<IoGridOutline
						size={25}
						onClick={() => setViewType(false)}
						className={cn(styles.view, {
							[styles.active]: isListView === false,
						})}
					/>
					<IoList
						size={25}
						onClick={() => setViewType(true)}
						className={cn(styles.view, {
							[styles.active]: isListView === true,
						})}
					/>
				</div>
			</div>
			<div className={styles.btnBlock}>
				<Button
					appearance="orange"
					className={cn(styles.cleanAllButton, {
						[styles.cleanAllButtonNone]: manufacturersFilter?.length === 0 && mainSearchString?.length === 0,
					})}
					onClick={() => onClearFilter()}
				>
					Очистить фильтры
				</Button>
				<Button appearance="orange" onClick={() => setIsOpen(!isOpen)} className={styles.filterBtn}>
					Фильтр
				</Button>
			</div>
			<div className={styles.divData}>
				{isMob ? (
					<div className={cn(styles.manufacturerFilter, { [styles.disabled]: isLoadData })}>
						<span className={cn(styles.marginBottom, styles.buttonText)}>Фильтр</span>
						<Input
							className={styles.marginBottom}
							placeholder={"Поиск"}
							value={leftPanelFilter}
							onChange={(e) => onLeftPanelFilterChange(e.target.value)}
						/>
						<div className={styles.manufacturerLabel} id="manufacturerFilter">
							{leftPanelChildren.map((x) => (
								<div className={styles.div} key={x.id}>
									<label htmlFor={x.id}>{x.name}</label>
									<input id={x.id} type={"checkbox"} className="manufacturerInput" />
								</div>
							))}
						</div>
						<Button appearance="green" onClick={onChange}>
							Применить
						</Button>
					</div>
				) : (
					isOpen && (
						<div className={styles.manufacturerLabelMob}>
							<span className={cn(styles.marginBottom, styles.buttonText)}>Фильтр</span>
							<Input
								className={styles.marginBottom}
								placeholder={"Поиск"}
								value={leftPanelFilter}
								onChange={(e) => onLeftPanelFilterChange(e.target.value)}
							/>
							<div className={styles.label}>
								{leftPanelChildren.map((x) => (
									<div className={styles.div} key={x.id}>
										<label htmlFor={x.id}>{x.name}</label>
										<input id={x.id} type={"checkbox"} className="manufacturerInput" />
									</div>
								))}
								<Button appearance="green" onClick={onChange}>
									Применить
								</Button>
							</div>
							<IoCloseSharp className={styles.close} color="#000" size="30" onClick={() => setIsOpen(!isOpen)} />
						</div>
					)
				)}
				{tableData.length !== 0 ? (
					<>
						<div
							className={cn({
								[styles.lineProductsDiv]: isListView === true,
								[styles.gridProductsDiv]: isListView === false,
							})}
						>
							{tableData.map((x) => (
								<ProductDiv isLine={isListView} key={nanoid()} propsBlock={x} />
							))}
						</div>
					</>
				) : isLoadData ? (
					<div className={styles.loader}>
						<div className={styles.ldsRing}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : (
					<p>Ничего не найдено</p>
				)}
			</div>
			{isLoadData && (
				<div className={styles.loader}>
					<div className={styles.ldsRing}>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}
			{isLoadMan && <Loader appearance="manufacturer" />}
		</div>
	) : (
		<Loader />
	);
};
