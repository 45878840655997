/* eslint-disable react-hooks/exhaustive-deps */
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "../components";
import { UsersInterface } from "../interface/Users.interface";
import { axiosPrivate } from "../settings/axios";
import styles from "../styles/Users.module.css";
import { toast } from "react-toastify";

export const Users = (): JSX.Element => {
	const [users, setUsers] = useState<UsersInterface[]>([]);
	const [isLoad, setIsLoad] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			try {
				const { status, data } = await axiosPrivate.get<UsersInterface[]>("/users");
				if (status === 200) {
					setUsers(data);
					setIsLoad(false);
				}
			} catch (e) {
				toast.error("Ошибка, попробуйте позже");
			}
		})();
	}, [isLoad]);

	const deleteUser = async (id: string) => {
		try {
			const { status } = await axiosPrivate.get(`/users/${id}/delete`);
			if (status === 200) {
				toast.success("Изменено");
				setIsLoad(true);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	const changePassword = async (id: string) => {
		try {
			const { status } = await axiosPrivate.post(`/users/changepassword`, { userId: id });
			if (status === 200) {
				toast.success("Изменено");
				setIsLoad(true);
			}
		} catch (e) {
			toast.error("Ошибка, попробуйте позже");
		}
	};

	return (
		<div className={styles.container}>
			{users.map((d) => (
				<div className={styles.userList} key={nanoid()}>
					<div className={styles.user}>
						<p>
							ФИО: <span>{d.fullName !== null ? d.fullName : "-"}</span>
						</p>
						<Button
							id={d.userName + "_btn"}
							appearance="orange"
							onClick={(e) => {
								const item = document.getElementById(d.userName + "_test");
								if (item?.style.display === "none") {
									document.getElementById(d.userName + "_btn")!.textContent = "Закрыть";
									item.style.display = "block";
								} else {
									document.getElementById(d.userName + "_btn")!.textContent = "Раскрыть";
									item && (item.style.display = "none");
								}
							}}
						>
							Раскрыть
						</Button>
					</div>
					<div id={d.userName + "_test"} style={{ display: "none" }}>
						<div className={styles.items} key={nanoid()}>
							<div className={styles.desc}>
								<p>
									Логин: <span>{d.userName}</span>
								</p>
								<p>Эл. почта: {d.email !== null ? d.email : "-"}</p>
								<p>Номер телефона: {d.phoneNumber !== null ? d.phoneNumber : "-"}</p>
							</div>
							<Button appearance="green" onClick={() => changePassword(d.id)}>
								Изменить пароль
							</Button>
							<Button appearance="red" onClick={() => deleteUser(d.id)}>
								Удалить пользователя
							</Button>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
