/* eslint-disable no-restricted-globals */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { jwtInterface } from "../../interface/Jwt.interface";
import { axiosPrivate } from "../../settings/axios";
import jwt_decode from "jwt-decode";

interface propsData {
	userName: string;
	password: string;
}

export const fetchUser = createAsyncThunk<propsData, {}>("user/fetchLogin", async (formData, thunkAPI) => {
	try {
		const { data } = await axiosPrivate.post("login", formData);
		const decToken: jwtInterface = jwt_decode(data.token);
		location.reload();
		Cookies.set("token", data.token);
		Cookies.set("refreshToken", data.refreshToken);
		Cookies.set("expiration", data.expiration);
		Cookies.set("role", decToken.Role);
		Cookies.set("fullName", decToken.FullName);
		return data;
	} catch (e) {
		return thunkAPI.rejectWithValue({ error: "Ошибка, проверте введенные данные" });
	}
});
