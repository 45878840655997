import { createContext, PropsWithChildren, useState } from "react";
import { EditProductInterface } from "../interface/Products.interface";

export interface HomeFilterInterface {
	autoPart: EditProductInterface | undefined;
	manufacturersFilter: string[] | null;
	setManufacturersFilter?: (newManufacturersFilter: string[] | null) => void;
	setAutoPart?: (newAutoPart: EditProductInterface | undefined) => void;
}

export const HomeContext = createContext<HomeFilterInterface>({
	autoPart: undefined,
	manufacturersFilter: null,
});

export const HomeFilterProvider = ({ children }: PropsWithChildren<HomeFilterInterface>): JSX.Element => {
	const [manufacturersFilterState, setManufacturersFilterState] = useState<string[] | null>([]);
	const [autoPartState, setAutoPartState] = useState<EditProductInterface | undefined>(undefined);

	const setManufacturersFilter = (newManufacturersFilter: string[] | null) => {
		setManufacturersFilterState(newManufacturersFilter);
	};

	const setAutoPart = (newAutoPart: EditProductInterface | undefined) => {
		setAutoPartState(newAutoPart);
	};

	return (
		<HomeContext.Provider
			value={{
				autoPart: autoPartState,
				manufacturersFilter: manufacturersFilterState,
				setManufacturersFilter,
				setAutoPart,
			}}
		>
			{children}
		</HomeContext.Provider>
	);
};
